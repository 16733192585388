// Functions
import remToPx from '@wearetla/tla-essentials-tools/functions/rem-to-px';

const scrollToElement = (elem, offset = false, source = window) => {
	let top = false;
	if(elem && source === window) {
		const box = elem.getBoundingClientRect();

		const body = document.body;
		const docEl = document.documentElement;

		const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;

		const clientTop = docEl.clientTop || body.clientTop || 0;

		top = box.top +  scrollTop - clientTop;
	}
	else if(elem) {
		const sourceBox = source.getBoundingClientRect();
		let box = elem.getBoundingClientRect();
		
		top = (box.top - sourceBox.top) + source.scrollTop;
	}

	if(top !== false) {
		source.scroll({
			top: parseInt(offset ? top + remToPx(offset) : top),
			behavior: 'smooth',
		});
	}
}

export default scrollToElement;