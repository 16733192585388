import '/assets/styles/pages/static.scss';
import '/assets/styles/pages/faq.scss';

import config from '/config';

import { useState, useEffect, useRef, useMemo } from 'react'

// Partials
import Img from '@wearetla/tla-essentials-tools/partials/img'
import Collapser from '/views/partials/collapser'
import Btn from '/views/partials/btn'
import Placeholder from '/views/partials/placeholder'
import StaticPageMenu from '/views/partials/static-page-menu'
import { Form, Input } from '/views/partials/forms'
import EmptyMessage from '/views/partials/empty-message'
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

// Hooks
import useFixedSideMenu from '/hooks/fixed-side-menu'
import useDebounce from '@wearetla/tla-essentials-tools/hooks/debounce'

// Functions
import scrollToElement from '/functions/scroll-to-element'

// Services
import siteServices from '/services/site'

// Context
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals'
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints'
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth'
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state'
import { useParams } from '@wearetla/tla-essentials-tools/utilities/navigator'

// Static Assets
import image_faq_banner from '/assets/images/faq/banner-v2.png';

// Data Endpoints
const getFaq = (id, ctx) => {
	return siteServices.getFaq(id, ctx);
}

const searchFaqs = (query) => {
	return siteServices.searchFaqs(query);
}

const FAQ = ({ match }) => {
	const { openModal } = useModals();
	const { mobile } = useBreakpoints();
	const { faqs } = useGlobalState();
	const { userData } = useAuth();

	const faqContentElem = useRef();
	const activeFaqSearchRequest = useRef(false);

	const params = useParams(['faq', 'faqDetail']);
	const faqID = useMemo(() => (params.id ? parseInt(params.id) : false), [params]);

	const [sideMenuFixed, sideMenuFixedEnd] = useFixedSideMenu(mobile);

	const [faqData, setFaqData] = useState(faqID ? false : null);
	const [searchValue, setSearchValue] = useState('');
	const [activeSearchValue, setActiveSearchValue] = useState(false);

	const debouncedSearchValue = useDebounce(searchValue, 1000);

	useEffect(() => {
		if(!activeSearchValue && faqID && (!faqData || (faqData && faqData.id !== faqID))) {
			if(faqData) {
				setFaqData(false);
			}

			getFaq(faqID).then((payload) => {
				setFaqData(payload);
			}).catch(() => {
			});
		}
		else if(activeSearchValue && (!faqData || (faqData && faqData.searchQuery !== activeSearchValue))) {
			if(activeFaqSearchRequest.current !== activeSearchValue) {
				activeFaqSearchRequest.current = activeSearchValue;
				setFaqData(faqID ? false : null);
	
				searchFaqs(activeSearchValue).then((payload) => {
					setFaqData(payload);
				}).catch(() => {
				}).finally(() => {
					activeFaqSearchRequest.current = false;
				})
			}
		}
		else if(!faqID && faqData !== null && !activeSearchValue) {
			setFaqData(null);
		}
	}, [faqData, faqID, activeSearchValue])

	useEffect(() => {
		if(faqID) {
			setSearchValue('');
			setActiveSearchValue(false);
		}
	}, [faqID])

	useEffect(() => {
		if(searchValue === '') {
			setActiveSearchValue(false);
		}
		else if(debouncedSearchValue.length > 0 && (activeSearchValue !== debouncedSearchValue && debouncedSearchValue === searchValue)) {
			setActiveSearchValue(debouncedSearchValue);
		}
	}, [debouncedSearchValue, searchValue, activeSearchValue])

	return (
		<main className="page static">
			<div className="section static-faq-banner">
				<div className="banner-wrap wrapper">
					<div className="banner-top">
						<h1 className="top-title">Merhaba, <br />Size nasıl yardımcı olabiliriz?</h1>

						<div className="top-opts">
							{userData &&
								<Btn
									onClick={() => {
										openModal('message', { contact: true, aboutOrder: true });
									}}
									icon="compose"
									className="pink">
									Siparişinizle ilgili soru sorun
								</Btn>
							}
							<Btn
								onClick={() => {
									openModal('message', { contact: true });
								}}
								icon="form"
								className="pink">
								{userData ? 'Diğer konular ile ilgili soru sorun' : 'Bize soru sorun'}
							</Btn>
						</div>
					</div>
					<div className="banner-mid">
						<span className="mid-seperator">Veya</span>
						<button
							onClick={() => { scrollToElement(faqContentElem.current, -2); }}
							className="mid-scrollbtn">
							<Icon name="scroll" />
							Yardım konularına <br className="only-mobile" />göz atın
						</button>
					</div>
					<div className="banner-bottom">
						<Btn
							icon="callcenter"
							tag="a"
							className="bottom-link outline primary-dark small"
							href={config.callCenterPhoneHref}>
							{config.callCenterPhone}
						</Btn>
						<Btn
							icon="envelope"
							tag="a"
							className="bottom-link outline primary-dark small"
							href={`mailto:${config.supportEmail}`}>
							{config.supportEmail}
						</Btn>

						<Img
							src={image_faq_banner}
							alt="Anne"
							className="bottom-image" />
					</div>
				</div>
			</div>
			<div className={`section static-wrap wrapper wide${sideMenuFixed ? ' fixed' : ''}${sideMenuFixedEnd ? ' fixed-end' : ''}`}>
				<Form
					id="faq-search"
					onSubmit={() => {
						setActiveSearchValue(searchValue);
					}}
					className={`section static-search${activeSearchValue ? ' active' : ''}`}>
						<strong className="search-title">
							<Icon className="title-icon" name="search" />
							Yardım konularında ara
						</strong>
						<div className="search-inputwrap">
							<Input
								value={searchValue}
								onChange={setSearchValue}
								name="search"
								label="Yardım konularında ara."
								className="search-input" />

							<Btn
								onClick={() => { setSearchValue(''); }}
								className="search-clearbtn rounded smaller"
								icon="close" />
						</div>
				</Form>
				<nav className="section static-menu">
					{!mobile &&
						<strong className="menu-title">
							<Icon className="title-icon" name="help" />
							Müşteri Hizmetleri & <br />Yardım
						</strong>
					}
					<StaticPageMenu
						emptyPlaceholder="Bir yardım konusu seçin."
						ignoreActive={activeSearchValue}
						elems={faqs ? faqs.map((faq, nth) => ({
							title: faq.name,
							href: 'faqDetail',
							noScroll: true,
							params: { slug: faq.slug, id: faq.id },
						})) : false}
						match={match}
						mobile={mobile} />
				</nav>
				<div className="section static-content" ref={faqContentElem}>
					{faqData ?
						<>
							{!activeSearchValue &&
								<h2 className="content-title">
									{faqData.name}
								</h2>
							}

							{(faqData && faqData.faqs.length > 1) ?
								<ul className="content-list">
									{faqData.faqs.map((faq, nth) => (
										<li className="list-node" key={nth}>
											<Collapser wysiwyg title={faq.question}>
												<span dangerouslySetInnerHTML={{__html: faq.answer }} />
											</Collapser>
										</li>
									))}
								</ul>
								:
								<div className="content-text wysiwyg">
									{faqData.faqs.length > 0 ?
										<span dangerouslySetInnerHTML={{__html: faqData.faqs[0].answer}} />
										:
										<EmptyMessage>
											Aramanız ile eşleşen bir yardım konusu bulunamadı.
										</EmptyMessage>
									}
								</div>
							}
						</>
						:
						<>
							{faqData === null ?
								<>
									<div className="content-text wysiwyg">
										<EmptyMessage icon="help">
											Bir Yardım Kategorisi Seçin veya Arama Yapın
										</EmptyMessage>
									</div>
								</>
								:
								<>
									{!activeSearchValue &&
										<h2 className="content-title">
											<Placeholder />
										</h2>
									}
									<div className="content-text wysiwyg">
										<Placeholder />
										<Placeholder />
										<Placeholder />
										<Placeholder />
										<Placeholder />
									</div>
								</>
							}
						</>
					}
				</div>
			</div>
		</main>
	)
}

// FAQ.defaultProps = {
// 	serverProps: {
// 		faqData: false,
// 	}
// }

// FAQ.getInitialProps = async ({req, res, match, ...ctx}) => {
// 	const defaultData = {
// 		error: false,
// 		notFound: false,
// 		faqData: false,
// 	}

// 	return new Promise((resolve) => {
// 		try {
// 			if (req) {
// 				if(match.params.id) {
// 					const promiseList = [
// 						getFaq(match.params.id, ctx),
// 					]
	
// 					Promise.all(promiseList).then(([
// 						faqPayload,
// 					]) => {
// 						resolve({
// 							...defaultData,
// 							faqData: faqPayload,
// 						})
// 					}).catch((e) => {
// 						resolve({
// 							...defaultData,
// 							error: e.request.res.statusCode !== 404,
// 							notFound: e.request.res.statusCode === 404,
// 						})
// 					})
// 				}
// 				else {
// 					resolve({
// 						...defaultData,
// 						faqData: null,
// 					})
// 				}
// 			}
// 			else {
// 				resolve(defaultData)
// 			}
// 		}
// 		catch (e) {
// 			resolve({
// 				...defaultData,
// 				error: true,
// 			})
// 		}
// 	});
// }

export default FAQ;